import classNames from 'classnames';
import React, {FC} from 'react';
import {ContentBox} from '../ContentBox';
import {Stack} from '../Stack';
import styles from './PromoBox.module.scss';
import {Background, Color} from '../../types/colors';

type PromoBoxContentProps = {
    isDark?: boolean;
    isHalfWidth?: boolean;
    background?: Background;
    color?: Color;
};

export const PromoBoxContent: FC<React.PropsWithChildren<PromoBoxContentProps>> = ({
    isDark = false,
    isHalfWidth,
    background,
    color,
    children,
}) => {
    return (
        <ContentBox
            className={classNames(styles.promoBoxContent, 'py-8 px-5 p-xl-15 pt-xl-20 mb-last-0', {
                [styles['promoBoxContent--dark']]: isDark,
                [styles['promoBoxContent--half-width']]: isHalfWidth,
            })}
            background={background ? background : isDark ? 'grey-dark' : 'grey-light'}
            color={color}
            rounded="xl"
        >
            <Stack className="h-100">{children}</Stack>
        </ContentBox>
    );
};
