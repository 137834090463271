import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './SearchPanel.module.scss';

type SearchPanelInputDropdownProps = {
	position: 'left' | 'middle' | 'right';
	visible?: boolean;
	onClickOutside?: () => void;
}

export const SearchPanelInputDropdown: FC<React.PropsWithChildren<SearchPanelInputDropdownProps>> = ({ position, visible, onClickOutside = () => {}, children }) => {
	const ref = useRef(null);

	useOnClickOutside(ref, onClickOutside);

	return (
		<div
			ref={ref}
			className={classNames(styles.searchPanelInputDropdown, styles[`searchPanelInputDropdown--${position}`], {
				[styles[`searchPanelInputDropdown--visible`]]: visible,
			})}
		>
			{children}
		</div>
	);
};
