import React, { FC } from 'react';
import styles from './InspirationCarousel.module.scss';
import classNames from "classnames";

type Props = {
    placeholder?: boolean;
}

export const InspirationCarouselSlide: FC<React.PropsWithChildren<Props>> = ({ children, placeholder }) => {
	return <div className={classNames(styles.inspirationCarouselSlide, {
        [styles.inspirationCarouselSlidePlaceholder]: placeholder,
    })}>{children}</div>;
};
