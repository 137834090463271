import classNames from 'classnames';
import React, { FC } from 'react';
import { Background, Color } from '../../types/colors';
import { ContentBox } from '../ContentBox';
import styles from './InfoBox.module.scss';
import { InfoBoxHeading } from './InfoBoxHeading';
import { InfoBoxText } from './InfoBoxText';

type InfoBoxProps = {
	variant?: 'default' | 'grey' | 'green' | 'transparent';
	className?: string;
	separatorBottom?: boolean;
	separatorLeft?: boolean;
}

type ColorVariants = Record<string, {
		color: Color;
		background: Background;
	}>;

const VARIANTS: ColorVariants = {
	default: {
		color: undefined,
		background: undefined,
	},
	grey: {
		color: 'white',
		background: 'grey-dark',
	},
	green: {
		color: 'white',
		background: 'green',
	},
	transparent: {
		color: undefined,
		background: false,
	},
};

export const InfoBox: FC<React.PropsWithChildren<InfoBoxProps>> = ({ variant = 'default', separatorBottom, separatorLeft, children }) => {
	const usedVariant = VARIANTS[variant];

	return (
		<ContentBox
			className={classNames(styles.infoBox, 'infoBox py-8 p-5 p-xl-16 px-xxl-12 pb-xxl-12 mb-last-0 h-100', {
				[styles[`infoBox--${variant}`]]: variant,
				[styles[`infoBox--sep-b`]]: separatorBottom,
				[styles[`infoBox--sep-l`]]: separatorLeft,
			})}
			color={usedVariant.color }
			background={usedVariant.background }
			rounded={variant === 'transparent' ? false : undefined}
		>
			{children}
		</ContentBox>
	);
};

export default Object.assign(InfoBox, {
	Heading: InfoBoxHeading,
	Text: InfoBoxText,
});
