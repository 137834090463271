import React, { FC } from 'react';
import styles from './PromoBox.module.scss';
import { PromoBoxContent } from './PromoBoxContent';
import { PromoBoxHeading } from './PromoBoxHeading';
import { PromoBoxImage } from './PromoBoxImage';

export const PromoBox: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={styles.promoBox}>{children}</div>;
};

export default Object.assign(PromoBox, {
	Content: PromoBoxContent,
	Heading: PromoBoxHeading,
	Image: PromoBoxImage,
});
