import NextImage from 'next/image';
import {FormattedMessage} from 'react-intl';

import {CarouselNav} from '@components/CarouselNav';
import {Container} from '@components/Container';
import {Icon} from '@components/Icon';
import {InspirationCarousel} from '@components/InspirationCarousel';
import {Typography} from '@components/Typography';
import {VisuallyHidden} from '@components/VisuallyHidden';
import {InspirationCard} from '@components/InspirationCard';

import {useCarousel} from '@hooks/useCarousel';
import {useLocale} from '@utils/locale';
import {BlogArticle} from '.cache/__types__';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {ShowAllArticles} from './ShowAllArticles';

type InspirationsType = {
    blogArticles: BlogArticle[];
};

export const Inspirations = ({blogArticles}: InspirationsType) => {
    const locale = useLocale();

    const {emblaRef, prevBtnDisabled, nextBtnDisabled, scrollPrev, scrollNext, scrollSnaps, selectedIndex} = useCarousel(
        {},
        {breakpoint: 'mdUp'},
    );

    if (!blogArticles.length) {
        return null;
    }

    return (
        <>
            <InspirationCarousel
                emblaRef={emblaRef}
                navButtons={
                    (!prevBtnDisabled || !nextBtnDisabled) && (
                        <CarouselNav>
                            <CarouselNav.Button onClick={scrollPrev} disabled={prevBtnDisabled}>
                                <Icon.ArrowLeft position="middle" />
                                <VisuallyHidden>
                                    <FormattedMessage defaultMessage="Předchozí" />
                                </VisuallyHidden>
                            </CarouselNav.Button>
                            <CarouselNav.Button onClick={scrollNext} disabled={nextBtnDisabled}>
                                <Icon.ArrowRight position="middle" />
                                <VisuallyHidden>
                                    <FormattedMessage defaultMessage="Následující" />
                                </VisuallyHidden>
                            </CarouselNav.Button>
                        </CarouselNav>
                    )
                }
                sideContent={
                    <>
                        <Typography as="h2" variant="h1" className="mb-8 mb-lg-20">
                            <FormattedMessage defaultMessage="Inspirace pro {br} váš domov" values={{br: <br />}} />
                        </Typography>

                        <ShowAllArticles className="mt-auto mb-auto d-none d-lg-block" locale={locale} />
                    </>
                }
                scrollSnaps={scrollSnaps}
                selectedIndex={selectedIndex}
            >
                {blogArticles.map((row) => (
                    <InspirationCarousel.Slide key={row.slug}>
                        <InspirationCard>
                            <InspirationCard.Image>
                                <NextImage
                                    src={row.mainImage?.url!}
                                    alt=""
                                    fill
                                    style={{
                                        maxWidth: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </InspirationCard.Image>

                            <InspirationCard.Content>
                                <InspirationCard.Heading>
                                    <Link href={getLocalizedRoute(routes.blogArticle, locale, {slug: row.slug})}>{row.title}</Link>
                                </InspirationCard.Heading>

                                <InspirationCard.Perex>{row.perex}</InspirationCard.Perex>
                            </InspirationCard.Content>
                        </InspirationCard>
                    </InspirationCarousel.Slide>
                ))}
            </InspirationCarousel>

            <Container className="d-lg-none position-relative">
                <ShowAllArticles className="mt-7 mt-md-n8 mt-lg-7 mb-0" locale={locale} />
            </Container>
        </>
    );
};
