import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './PromoCardLarge.module.scss';

export const PromoCardLargeHeading: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="h3" variant="h4" className={classNames(styles.promoCardLargeHeading, 'mb-5 mb-xl-8')}>
			{children}
		</Typography>
	);
};
