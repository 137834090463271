import classNames from 'classnames';
import React, {forwardRef, ReactNode, useState} from 'react';
import {Form, FormProps} from '../Form';
import styles from './SearchPanel.module.scss';
import {SearchPanelInputButtonLabel} from './SearchPanelInputButtonLabel';

type SearchPanelInputProps = FormProps['Control'] & {
    label: ReactNode;
};

export const SearchPanelInput = forwardRef<HTMLInputElement, SearchPanelInputProps>(
    ({label, onFocus, onBlur, className, ...props}, ref) => {
        const [focused, setFocused] = useState(false);

        return (
            <label
                className={classNames(className, styles.searchPanelInputButton, 'btn btn-blank', {
                    active: focused,
                })}
            >
                <SearchPanelInputButtonLabel>{label}</SearchPanelInputButtonLabel>

                <Form.Control
                    ref={ref}
                    {...props}
                    className={styles.searchPanelInput}
                    onFocus={(event) => {
                        setFocused(true);
                        onFocus?.(event);
                    }}
                    onBlur={(event) => {
                        setFocused(false);
                        onBlur?.(event);
                    }}
                />
            </label>
        );
    },
);
