import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import styles from './NumbersList.module.scss';
import {NumbersListItem} from './NumbersListItem';

type NumbersListProps = {
    columns?: 3 | 4;
    className?: string;
};

export const NumbersList = ({columns = 4, className, children}: PropsWithChildren<NumbersListProps>) => {
    return (
        <ul
            className={classNames(
                styles.numbersList,
                'numbersList mb-0',
                {
                    [styles[`numbersList--${columns}`]]: columns,
                },
                className,
            )}
        >
            {children}
        </ul>
    );
};

export default Object.assign(NumbersList, {
    Item: NumbersListItem,
});
