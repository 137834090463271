import {ReactNode, useCallback, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col} from '@components/Col';
import {Form} from '@components/Form';
import {InfoTooltip} from '@components/InfoTooltip';
import {NewsletterBox} from '@components/NewsletterBox';
import {Row} from '@components/Row';
import {Typography} from '@components/Typography';
import {useLocale} from '@utils/locale';
import {LocaleEnum} from 'src/types/general';
import {Formik, FormikHelpers} from 'formik';
import {useNewsletterSubscribeMutation} from '@gql/mutation/user/newsletterSubscribe.gql';
import * as yup from 'yup';
import {Input} from '@liveComponents/Field';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useToaster} from '@utils/toast';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {MarketingConsentOrigin} from '.cache/__types__';
import {useMQ} from '@hooks/useMQ';
import {RecaptchaClaim} from '@liveComponents/RecaptchaClaim';
import {SubmitButton} from '@liveComponents/Field/SubmitButton';
import classNames from 'classnames';

type BannerProps = {
    origin: MarketingConsentOrigin;
    portrait?: boolean;
    heading?: ReactNode;
    content?: ReactNode;
};

type FormValues = {
    email: string;
};

export const NewsletterBanner = ({origin, portrait, heading, content}: BannerProps) => {
    const locale = useLocale();
    const intl = useIntl();
    const toaster = useToaster();
    const isMdDown = useMQ('mdDown');
    const {executeRecaptcha} = useGoogleReCaptcha();

    const [success, setSuccess] = useState(false);
    const [subscribe] = useNewsletterSubscribeMutation();

    const handleSubmit = useCallback(
        (values: FormValues, actions: FormikHelpers<FormValues>) => {
            if (!executeRecaptcha) {
                toaster.toast({
                    message: (
                        <FormattedMessage defaultMessage="Stránka ještě není plně načtena. Vyčkejte prosím jejího načtení a zkuste to znovu." />
                    ),
                    header: <FormattedMessage defaultMessage="Upozornění" />,
                    bg: 'warning',
                    color: 'white',
                    duration: 5000,
                });
                actions.setSubmitting(false);
                return;
            }

            void executeRecaptcha('consent').then((token) =>
                subscribe({
                    variables: {
                        email: values.email,
                        origin,
                        recaptcha: token,
                    },
                }).then(() => {
                    setSuccess(true);
                }),
            );
        },
        [executeRecaptcha, toaster, subscribe, origin],
    );

    const requiredMessage = useMemo(() => intl.formatMessage({defaultMessage: 'Povinné pole'}), [intl]);

    if (locale !== LocaleEnum.cs) {
        return null;
    }

    return (
        <NewsletterBox portrait={portrait}>
            <NewsletterBox.Heading>
                {heading ?? <FormattedMessage defaultMessage="Odebírejte novinky z realitního trhu" />}
            </NewsletterBox.Heading>

            {!!content && <NewsletterBox.Content background="green">{content}</NewsletterBox.Content>}

            <NewsletterBox.Content>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={yup.object({
                        email: yup
                            .string()
                            .email(intl.formatMessage({defaultMessage: 'Neplatný email'}))
                            .required(requiredMessage),
                    })}
                >
                    {({handleSubmit}) => (
                        <Form onSubmit={handleSubmit} className="position-relative">
                            <Row className={'g-2' + (success ? ' opacity-0' : '')}>
                                <Col md={!portrait ? 10 : 16}>
                                    <Input
                                        name="email"
                                        label=""
                                        placeholder={intl.formatMessage({defaultMessage: 'Vložte e-mailovou adresu'})}
                                        className="mb-2"
                                        append={
                                            <InfoTooltip
                                                trigger={isMdDown ? ['hover', 'focus', 'click'] : 'click'}
                                                content={
                                                    <>
                                                        Odesláním souhlasíte se zpracováním osobních údajů za účelem nabízení a zpracováním
                                                        marketingových nabídek společnosti Bezrealitky s.r.o.. Máte právo svůj souhlas
                                                        odvolat. Více informací v{' '}
                                                        <Link
                                                            href={getLocalizedRoute(routes.page, locale, {
                                                                uri: 'smluvni-podminky/zasady-zpracovani-osobnich-udaju-marketing',
                                                            })}
                                                            passHref
                                                        >
                                                            zásadách zpracování osobních údajů
                                                        </Link>
                                                        .
                                                    </>
                                                }
                                            />
                                        }
                                    />
                                </Col>

                                <Col md={!portrait ? 6 : 16}>
                                    <SubmitButton className="w-100">
                                        <FormattedMessage defaultMessage="Odebírat" />
                                    </SubmitButton>
                                </Col>
                                <Col className={classNames({'mt-0': !portrait})}>
                                    <RecaptchaClaim className="mb-0" />
                                </Col>
                            </Row>
                            <Typography
                                as="h5"
                                className={
                                    'text-muted text-center position-absolute top-50 translate-middle-y w-100 ' +
                                    (!success ? ' visually-hidden' : '')
                                }
                                fontWeight="normal"
                            >
                                <FormattedMessage
                                    defaultMessage="Potvrďte prosím svou emailovou adresu {br}a můžete se těšit na první novinky z Bezrealitky."
                                    values={{
                                        br: <br className="d-none d-sm-inline" />,
                                    }}
                                />
                            </Typography>
                        </Form>
                    )}
                </Formik>
            </NewsletterBox.Content>
        </NewsletterBox>
    );
};
