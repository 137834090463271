import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import styles from './SearchPanelSidebar.module.scss';

export const SearchPanelSidebarBodyHeader: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.searchPanelSidebarBodyHeader, 'position-sticky p-0 py-4 mb-4')} rounded={false}>
			{children}
		</ContentBox>
	);
};
