import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import styles from './CtaBlock.module.scss';

type CtaBlockProps = {
    variant?: 'simple' | 'single';
};

export const CtaBlock = ({variant, children}: PropsWithChildren<CtaBlockProps>) => {
    return (
        <div
            className={classNames(styles.ctaBlock, 'ctaBlock', {
                [styles[`ctaBlock--${variant}`]]: variant,
            })}
        >
            {children}
        </div>
    );
};

export default Object.assign(CtaBlock, {});
