import React, {FC, forwardRef, PropsWithChildren} from 'react';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import styles from './HeroIntro.module.scss';

type HeroIntroPromoButton = ButtonProps;

export const HeroIntroPromoButton: FC<PropsWithChildren<HeroIntroPromoButton>> = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => {
	return (
		<div className={styles.heroIntroPromoButton}>
			<Button ref={ref} {...props} variant="outline-light" size="lg" sideToSide className="w-100">
				<Typography variant="cta">{children}</Typography>
				<Icon.ArrowRight position="end" />
			</Button>
		</div>
	);
});
