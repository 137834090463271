import classNames from 'classnames';
import React, {FC} from 'react';
import styles from './HeroIntro.module.scss';

export type HeroIntroBackgroundProps = {
    gradient?: boolean;
};

export const HeroIntroBackground: FC<React.PropsWithChildren<HeroIntroBackgroundProps>> = ({gradient, children}) => {
    return (
        <div className={classNames(styles.heroIntroBackground, 'overflow-hidden', {[styles[`heroIntroBackground--gradient`]]: gradient})}>
            {children}
        </div>
    );
};
