import classNames from 'classnames';
import React, { FC, useContext } from 'react';
import { Typography } from '../Typography';
import styles from './PromoCardLarge.module.scss';
import { PromoCardLargeContext } from './PromoCardLargeContext';

export const PromoCardLargeDesc: FC<React.PropsWithChildren> = ({ children }) => {
	const { variant } = useContext(PromoCardLargeContext);

	return (
		<Typography
			as="p"
			variant="h5"
			className={classNames(styles.promoCardLargeDesc, 'mb-0')}
			fontWeight="normal"
			color={variant === 'green' || variant === 'blue' ? 'current' : 'grey-dark'}
		>
			{children}
		</Typography>
	);
};
