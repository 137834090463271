import {Dispatch} from 'react';
import {FormattedMessage} from 'react-intl';

import {Box} from '@components/Box';
import {Col} from '@components/Col';
import {Row} from '@components/Row';
import {ToggleButton} from '@components/ToggleButton';
import {Typography} from '@components/Typography';
import {OfferType} from '.cache/__types__';
import {Action, RadioFilterEnum} from './types';
import {setRadioFilter} from './reducer';

type SearchPanelOfferProps = {
    dispatch: Dispatch<Action>;
};

export const SearchPanelOffer = ({dispatch}: SearchPanelOfferProps) => {
    return (
        <Row className="g-3 mb-8">
            <Col xs={12}>
                <ToggleButton
                    size="lg"
                    type="radio"
                    name="typ-nabidky"
                    id="pronajem"
                    value="pronajem"
                    onClick={setRadioFilter(dispatch)({attr: RadioFilterEnum.Offer, value: OfferType.Pronajem})}
                >
                    <Box as="span" className="d-block w-100 text-start">
                        <Typography className="d-block" variant="subheadline" fontWeight="medium">
                            <FormattedMessage defaultMessage="Pronájem" />
                        </Typography>
                        <Typography className="d-block mt-1" color="grey-dark">
                            <FormattedMessage defaultMessage="Za měsíční poplatek bydlíte." />
                        </Typography>
                    </Box>
                </ToggleButton>
            </Col>

            <Col xs={12}>
                <ToggleButton
                    size="lg"
                    type="radio"
                    name="typ-nabidky"
                    id="prodej"
                    value="prodej"
                    onClick={setRadioFilter(dispatch)({attr: RadioFilterEnum.Offer, value: OfferType.Prodej})}
                >
                    <Box as="span" className="d-block w-100 text-start">
                        <Typography className="d-block" variant="subheadline" fontWeight="medium">
                            <FormattedMessage defaultMessage="Prodej" />
                        </Typography>
                        <Typography className="d-block mt-1" color="grey-dark">
                            <FormattedMessage defaultMessage="Nemovitost si koupíte." />
                        </Typography>
                    </Box>
                </ToggleButton>
            </Col>
        </Row>
    );
};
