import React, { FC } from 'react';
import { Col } from '../Col';
import { Typography } from '../Typography';
import styles from './NewsletterBox.module.scss';
import {useNewsletterBox} from "@components/NewsletterBox/NewsletterBox";

export const NewsletterBoxHeading: FC<React.PropsWithChildren> = ({ children }) => {
    const {portrait} = useNewsletterBox();
	return (
		<Col lg={!portrait ? 6 : 16} xl={! portrait ? 8 : 16}>
			<Typography variant="h3" className={styles.newsletterBoxHeading}>
				{children}
			</Typography>
		</Col>
	);
};
