import classNames from 'classnames';
import React, { FC } from 'react';
import { ButtonGroup } from '../ButtonGroup';
import styles from './SearchPanel.module.scss';

type SearchPanelInputsItemProps = {
	className?: string;
}

export const SearchPanelInputsItem: FC<React.PropsWithChildren<SearchPanelInputsItemProps>> = ({ className, children }) => {
	return <ButtonGroup className={classNames(styles.searchPanelInputsItem, className)}>{children}</ButtonGroup>;
};
