import useEmblaCarousel from 'embla-carousel-react';
import {EmblaOptionsType} from 'embla-carousel';
import {useState, useCallback, useEffect} from 'react';
import {MediaQueriesProps, useMQ} from '@hooks/useMQ';

export const useCarousel = (
    props: EmblaOptionsType = {},
    additionalOptions: {breakpoint: MediaQueriesProps} = {breakpoint: 'allUp'},
    plugins: Parameters<typeof useEmblaCarousel>['1'] = [],
) => {
    const {...options} = props;
    const {breakpoint} = additionalOptions;
    const defaultOptions: Partial<EmblaOptionsType> = {
        dragFree: true,
        containScroll: 'trimSnaps',
    };
    const mergedOptions = {...defaultOptions, ...options};

    const [emblaRef, embla] = useEmblaCarousel({...mergedOptions}, plugins);
    const [emblaInit, setEmblaInit] = useState(true);
    const isBreakpoint = useMQ(breakpoint);

    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([] as number[]);

    const scrollPrev = useCallback(() => {
        embla && embla.scrollPrev();
    }, [embla]);
    const scrollNext = useCallback(() => {
        embla && embla.scrollNext();
    }, [embla]);
    const scrollTo = useCallback(
        (index: any) => {
            embla && embla.scrollTo(index);
        },
        [embla],
    );

    const onSelect = useCallback(() => {
        if (!embla) {
            return;
        }

        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnDisabled(!embla.canScrollPrev());
        setNextBtnDisabled(!embla.canScrollNext());
    }, [embla]);

    const checkInitOrDestroy = useCallback(() => {
        setEmblaInit(isBreakpoint);
    }, [setEmblaInit, isBreakpoint]);

    useEffect(() => {
        if (!embla) {
            return;
        }

        onSelect();
        setScrollSnaps(embla.scrollSnapList());

        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    useEffect(() => {
        checkInitOrDestroy();

        window.addEventListener('resize', checkInitOrDestroy);

        return () => {
            window.removeEventListener('resize', checkInitOrDestroy);
        };
    }, [checkInitOrDestroy]);

    return emblaInit
        ? {emblaRef, embla, prevBtnDisabled, nextBtnDisabled, scrollSnaps, selectedIndex, scrollPrev, scrollNext, scrollTo}
        : {emableRef: null};
};
