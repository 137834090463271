import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './InfoBox.module.scss';

export const InfoBoxText: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="p" variant="perex" className={classNames(styles.infoBoxText, 'mt-auto')}>
			{children}
		</Typography>
	);
};
