import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography, TypographyProps } from '../Typography';
import styles from './PromoBox.module.scss';

type PromoBoxHeadingProps = {
	className?: string;
	variant?: TypographyProps['variant'];
}

export const PromoBoxHeading: FC<React.PropsWithChildren<PromoBoxHeadingProps>> = ({ className, variant = 'title', children }) => {
	return (
		<Typography as="h2" variant={variant} className={classNames(styles.promoBoxHeading, 'mb-7 mb-md-10 mb-lg-18', className)}>
			{children}
		</Typography>
	);
};
