import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './SearchPanelSidebar.module.scss';
import { SearchPanelSidebarBody } from './SearchPanelSidebarBody';
import { SearchPanelSidebarBodyHeader } from './SearchPanelSidebarBodyHeader';
import { SearchPanelSidebarHeader } from './SearchPanelSidebarHeader';

export const SearchPanelSidebar: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={classNames(styles.searchPanelSidebar, 'searchPanelSidebar d-flex flex-column h-100')}>{children}</div>;
};

export default Object.assign(SearchPanelSidebar, {
	Body: SearchPanelSidebarBody,
	BodyHeader: SearchPanelSidebarBodyHeader,
	Header: SearchPanelSidebarHeader,
});
