import {Dispatch} from 'react';

import {SearchPanelSidebar} from '@components/SearchPanelSidebar';
import {Autocomplete} from '@liveComponents/Autocomplete/Autocomplete';

import {Action, ActionTypes, IntFilterEnum} from './types';
import {useLocale} from '@utils/locale';
import {LocaleEnum} from 'src/types/general';
import {initialState, onSelectFeature} from './reducer';

type SearchPanelLocalityProps = {
    dispatch: Dispatch<Action>;
};

export const SearchPanelLocality = ({dispatch}: SearchPanelLocalityProps) => {
    const locale = useLocale();
    return (
        <SearchPanelSidebar.BodyHeader>
            <Autocomplete
                countryCode={locale === LocaleEnum.sk ? 'sk' : 'cz'}
                onSelect={onSelectFeature(dispatch)}
                resultOverlay={false}
                currentLocation={{
                    position: 'separate',
                    setCurrentLocationUsed: (value) => {
                        dispatch({
                            type: ActionTypes.setIntFilter,
                            attr: IntFilterEnum.PolygonBuffer,
                            value: value ? 5000 : initialState.filter.polygonBuffer,
                        });
                    },
                }}
            />
        </SearchPanelSidebar.BodyHeader>
    );
};
