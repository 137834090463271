import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './InspirationCard.module.scss';

export const InspirationCardHeading: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="h3" className={classNames(styles.inspirationCardHeading, 'mb-3 mb-lg-5')}>
			{children}
		</Typography>
	);
};
