import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Form} from '@components/Form';
import {LocaleEnum, LocaleType} from 'src/types/general';
import {useLocale} from '@utils/locale';

export const formatMoneyValue = (locale: LocaleType, value: number) =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: locale === LocaleEnum.sk ? 'EUR' : 'CZK',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(value);

type Props = any;
//type Props = any & {dispatch: Dispatch<Action>};

export const Range: FC<React.PropsWithChildren<Props>> = (props) => {
    const intl = useIntl();
    const locale = useLocale();

    return (
        <Form.RangeSlider
            {...props}
            ariaLabel={[intl.formatMessage({defaultMessage: 'Minimální cena'}), intl.formatMessage({defaultMessage: 'Maximální cena'})]}
        >
            {props.value && <Form.RangeSlider.Label>{formatMoneyValue(locale, props.value[0])}</Form.RangeSlider.Label>}
            {props.value && <Form.RangeSlider.Label>{formatMoneyValue(locale, props.value[1])}</Form.RangeSlider.Label>}
        </Form.RangeSlider>
    );
};
