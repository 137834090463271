import React, { FC, ReactNode } from 'react';
import { Button, ButtonProps } from '../Button';
import { Typography } from '../Typography';
import styles from './SearchPanel.module.scss';
import { SearchPanelInputButtonLabel } from './SearchPanelInputButtonLabel';
import classNames from "classnames";

type SearchPanelInputButtonProps = ButtonProps & {
	label?: ReactNode;
};

export const SearchPanelInputButton: FC<React.PropsWithChildren<SearchPanelInputButtonProps>> = ({ label, children, className, ...props }) => {
	return (
		<Button {...props} variant="blank" className={classNames(className, styles.searchPanelInputButton)}>
			{label && <SearchPanelInputButtonLabel>{label}</SearchPanelInputButtonLabel>}

			<Typography
                className={"d-none d-md-inline"}
				as="span"
				variant={'body'}
				truncate
			>
				{children}
			</Typography>

            <Typography
                className={"d-md-none"}
                as="span"
                variant={'caption'}
                textTransform={'uppercase'}
                fontWeight={'bold'}
                letterSpacing
                truncate
            >
                {children}
            </Typography>
		</Button>
	);
};
