import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import style from './SearchPanel.module.scss';

export const SearchPanelInputButtonLabel: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography
			as="span"
			variant="caption"
			textTransform="uppercase"
			fontWeight="medium"
			color="grey-dark"
			letterSpacing
			className={classNames(style.searchPanelInputButtonLabel, 'd-none d-md-block mb-2')}
			truncate
		>
			{children}
		</Typography>
	);
};
