import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import styles from './CtaCard.module.scss';
import {CtaCardButton} from './CtaCardButton';
import {CtaCardHeading} from './CtaCardHeading';
import {CtaCardText} from './CtaCardText';

export const CtaCard = ({children}: PropsWithChildren) => {
    return <article className={classNames(styles.ctaCard, 'ctaCard d-flex flex-column p-6')}>{children}</article>;
};

export default Object.assign(CtaCard, {
    Heading: CtaCardHeading,
    Text: CtaCardText,
    Button: CtaCardButton,
});
