import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './InfoBox.module.scss';

export const InfoBoxHeading: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="h3" className={classNames(styles.infoBoxHeading, 'mb-5 mb-xl-6')}>
			{children}
		</Typography>
	);
};
