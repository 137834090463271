import {Dispatch} from 'react';

import {Box} from '@components/Box';
import {Button} from '@components/Button';
import {Form} from '@components/Form';
// import {Range} from '@pageComponents/HomePage/Range';

import {Action, CheckBoxEnum, IntFilterEnum, State} from './types';
// import {onChangeRanger, onClickNoRange} from './reducer';
import Link from 'next/link';
import {FormattedMessage, useIntl} from 'react-intl';
import {routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import {getSearchURL} from '@utils/query';
import {InputGroup} from '@components/InputGroup';
import {setCurrencyFilter, setIntFilter, toggleCheckBox} from './reducer';
import {Tag} from '@components/Tag';
import {priceToData} from './Search';
import {OfferType, Currency} from '.cache/__types__';
import {getIsRent} from '@pageComponents/Insert/utils';

type SearchPanelPriceProps = {
    state: State;
    dispatch: Dispatch<Action>;
};

export const SearchPanelPrice = ({state, dispatch}: SearchPanelPriceProps) => {
    const locale = useLocale();
    const intl = useIntl();
    const isRent = state.filter.offerType[0] ? getIsRent(state.filter.offerType[0]) : false;

    return (
        <>
            <Box className="mb-10 mb-last-0">
                <InputGroup>
                    <Form.Control
                        placeholder={intl.formatMessage({defaultMessage: 'Cena od'})}
                        value={state.filter.priceFrom ?? ''}
                        onChange={setIntFilter(dispatch)(IntFilterEnum.PriceFrom)}
                    />
                    <InputGroup.Text>
                        <Form.Select name="currency" value={state.filter.currency!} className="ms-0" onChange={setCurrencyFilter(dispatch)}>
                            {[
                                {
                                    name: 'Kč',
                                    value: Currency.Czk,
                                },
                                {
                                    name: 'EUR',
                                    value: Currency.Eur,
                                },
                            ].map(({name, value}) => (
                                <option key={value} value={value}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mt-3">
                    <Form.Control
                        placeholder={intl.formatMessage({defaultMessage: 'Cena do'})}
                        value={state.filter.priceTo ?? ''}
                        onChange={setIntFilter(dispatch)(IntFilterEnum.PriceTo)}
                    />
                    <InputGroup.Text>
                        <Form.Select name="currency" value={state.filter.currency!} className="ms-0" onChange={setCurrencyFilter(dispatch)}>
                            {[
                                {
                                    name: 'Kč',
                                    value: Currency.Czk,
                                },
                                {
                                    name: 'EUR',
                                    value: Currency.Eur,
                                },
                            ].map(({name, value}) => (
                                <option key={value} value={value} selected={state.filter.currency === value}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup.Text>
                </InputGroup>
                {isRent && (
                    <Form.Check
                        id="searchPriceWithCharges"
                        type="checkbox"
                        label={intl.formatMessage({defaultMessage: 'Včetně energií a služeb'})}
                        className="my-md-5 my-3"
                        onChange={toggleCheckBox(dispatch)(CheckBoxEnum.SearchPriceWithCharges)}
                        checked={state.filter.searchPriceWithCharges}
                    />
                )}

                {priceToData[state.filter.offerType[0] as OfferType.Prodej | OfferType.Pronajem].map((row) => (
                    <Tag size="xs" variant="dark" className="mt-3 me-2 cursor-pointer">
                        <a
                            onClick={() => {
                                setIntFilter(dispatch)(IntFilterEnum.PriceTo)({
                                    target: {value: row.values[locale]},
                                } as React.ChangeEvent<HTMLInputElement>);
                            }}
                        >
                            {state.filter.offerType[0] === OfferType.Prodej ? (
                                <FormattedMessage defaultMessage="Do {priceTo} mil" values={{priceTo: row.title[locale]}} />
                            ) : (
                                <FormattedMessage defaultMessage="Do {priceTo} tis" values={{priceTo: row.title[locale]}} />
                            )}
                        </a>
                    </Tag>
                ))}
            </Box>

            <Link href={getSearchURL(routes.search, locale)(state.filter)} passHref legacyBehavior>
                <Button className="w-100">
                    <FormattedMessage defaultMessage="Hledat" />
                </Button>
            </Link>
        </>
    );
};
