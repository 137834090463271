import classNames from 'classnames';
import React, {FC} from 'react';
import {Box} from '../Box';
import {Image} from '../Image';
import styles from './PromoBox.module.scss';

type PromoBoxImageProps = {
    isHalfWidth?: boolean;
};

export const PromoBoxImage: FC<React.PropsWithChildren<PromoBoxImageProps>> = ({isHalfWidth, children}) => {
    return (
        <Box
            className={classNames(styles.promoBoxImage, 'overflow-hidden position-relative', {
                [styles['promoBoxImage--half-width']]: isHalfWidth,
            })}
            rounded="xl"
        >
            <Image layout="fill">{children}</Image>
        </Box>
    );
};
