import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import styles from './SearchPanelSidebar.module.scss';

export const SearchPanelSidebarHeader: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.searchPanelSidebarHeader, 'pb-12')} rounded={false} background="blue" color="white">
			{children}
		</ContentBox>
	);
};
