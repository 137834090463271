import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Box, BoxProps } from '../Box';
import styles from './MainContent.module.scss';

type MainContentProps = BoxProps & {
	fill?: boolean;
};

export const MainContent: FC<React.PropsWithChildren<MainContentProps>> = ({ fill, className, children }) => {
	const { height } = useWindowSize();

	useEffect(() => {
		document.documentElement.style.setProperty('--viewport-height', `${height}px`);

		return () => {
			document.documentElement.style.removeProperty('--viewport-height');
		};
	}, [height]);

	return (
		<Box
			as="main"
			className={classNames(styles.mainContent, 'mainContent', className, {
				[styles['mainContent--fill']]: fill,
			})}
		>
			{children}
		</Box>
	);
};
