import {FC, ComponentProps, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {Typography} from '../Typography';
import styles from './Tag.module.scss';
import {TagClose} from './TagClose';

export type TagProps = {
    variant?: 'green' | 'green-light' | 'red' | 'outline' | 'outline-medium' | 'dark' | 'white' | 'light-grey' | 'brown' | 'orange' | 'blue';
    size?: 'xs' | 'sm';
    flat?: boolean;
    className?: string;
    color?: string;
} & Omit<ComponentProps<typeof Typography>, 'variant' | 'color'>;

export const Tag: FC<PropsWithChildren<TagProps>> = ({variant = 'green', size, flat, className, children, color, ...typographyProps}) => {
    return (
        <Typography
            {...typographyProps}
            variant="caption"
            className={classNames(styles.tag, 'tag', className, {
                [styles[`tag--${variant}`]]: variant,
                [styles[`tag--${size}`]]: size,
                [styles[`tag--rounded`]]: !flat,
                [styles[`bg_${color}`]]: color,
            })}
        >
            {children}
        </Typography>
    );
};

export default Object.assign(Tag, {
    Close: TagClose,
});
