import {FormattedMessage} from 'react-intl';
import {LocaleType} from 'src/types/general';

import {Button} from '@components/Button';
import {Icon} from '@components/Icon';
import {Typography} from '@components/Typography';
import {getLocalizedRoute, routes} from '@utils/routes';

export const ShowAllArticles = ({className, locale}: {className?: string; locale: LocaleType}) => (
    <Typography as="p" className={className}>
        <Button href={getLocalizedRoute(routes.blog, locale)} variant="link">
            <FormattedMessage defaultMessage="Zobrazit všechny články" />
            <Icon.ArrowRight position="end" />
        </Button>
    </Typography>
);
