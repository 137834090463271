import classNames from 'classnames';
import React, { FC } from 'react';
import { ButtonGroup } from '../ButtonGroup';
import styles from './CarouselNav.module.scss';
import { CarouselNavButton } from './CarouselNavButton';

type CarouselNavProps = {
	size?: 'sm' | 'md';
	className?: string;
}

export const CarouselNav: FC<React.PropsWithChildren<CarouselNavProps>> = ({ size = 'md', className, children }) => {
	return (
		<ButtonGroup
			className={classNames(styles.carouselNav, 'carouselNav', className, {
				[styles[`carouselNav--${size}`]]: size,
			})}
		>
			{children}
		</ButtonGroup>
	);
};

export default Object.assign(CarouselNav, {
	Button: CarouselNavButton,
});
