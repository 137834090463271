import {NumbersList} from '@components/NumbersList';
import {useIntl} from 'react-intl';

export const IntroNumbers = () => {
    const intl = useIntl();

    return (
        <NumbersList>
            <NumbersList.Item label={intl.formatMessage({defaultMessage: 'Návštěv měsíčně'})}>1&nbsp;665&nbsp;397</NumbersList.Item>

            <NumbersList.Item label={intl.formatMessage({defaultMessage: 'Vložených inzerátů měsíčně'})}>17&nbsp;250</NumbersList.Item>

            <NumbersList.Item label={intl.formatMessage({defaultMessage: 'Spokojených zákazníků'})}>1&nbsp;259&nbsp;535</NumbersList.Item>

            <NumbersList.Item label={intl.formatMessage({defaultMessage: 'Zkušeností na trhu'})}>17 let</NumbersList.Item>
        </NumbersList>
    );
};
