import React, {ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Modal} from "@components/Modal";
import {Row} from "@components/Row";
import {Image} from "@components/Image";
import NextImage from "next/image";
import {Col} from "@components/Col";
import SEImage from 'images/newsletter/popup.jpeg';
import {Typography} from "@components/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import {Form} from "@components/Form";
import {Button} from "@components/Button";
import {useEmailByUlidQuery} from "@gql/query/user/emailByUlid.gql";
import {useNewsletterSubscribeMutation} from "@gql/mutation/user/newsletterSubscribe.gql";
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {Input} from "@liveComponents/Field";
import * as yup from "yup";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useToaster} from "@utils/toast";
import {MarketingConsentOrigin} from "../../../.cache/__types__";
import {RecaptchaClaim} from "@liveComponents/RecaptchaClaim";
import {SubmitButton} from "@liveComponents/Field/SubmitButton";

type Props = ComponentProps<typeof Modal> & { ulid?: string };
type FormValues = {
    email: string;
};

export const SEModal: FC<React.PropsWithChildren<Props>> = (props) => {
    const [email, setEmail] = useState('');
    const intl = useIntl();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const toaster = useToaster();
    const [success, setSuccess] = useState(false);
    const formRef = useRef<FormikProps<FormValues>>(null);
    const {data} = useEmailByUlidQuery({
        variables: {
            ulid: props.ulid ?? '',
        },
        skip: !props.ulid,
    });

    const [subscribe] = useNewsletterSubscribeMutation();

    useEffect(() => {
        if (data?.email) {
            setEmail(data.email);
            formRef.current?.setFieldValue('email', data.email);
        }
    }, [data?.email]);


    const handleSubmit = useCallback(
        (values: FormValues, actions: FormikHelpers<FormValues>) => {
            if (!executeRecaptcha) {
                toaster.toast({
                    message: (
                        <FormattedMessage
                            defaultMessage={'Stránka ještě není plně načtena. Vyčkejte prosím jejího načtení a zkuste to znovu.'}
                        />
                    ),
                    header: <FormattedMessage defaultMessage={'Upozornění'}/>,
                    bg: 'warning',
                    color: 'white',
                    duration: 5000,
                });
                actions.setSubmitting(false);
                return;
            }
            executeRecaptcha('consent').then((token) =>
                subscribe({
                    variables: {
                        email: values.email,
                        origin: MarketingConsentOrigin.Popup,
                        recaptcha: token,
                    },
                }).then(() => {
                    setSuccess(true);
                }).finally(() => {
                    actions.setSubmitting(false);
                }),
            );
        },
        [executeRecaptcha, toaster, subscribe],
    );

    const requiredMessage = useMemo(() => intl.formatMessage({defaultMessage: 'Povinné pole'}), [intl]);

    return (
        <Modal size={"xl"} {...props}>
            <Modal.Header closeButton>
                {/*<Modal.Title>
                    <Typography as="h2" variant="subheadline" className="mb-0">
                        <FormattedMessage defaultMessage={"Přihlaste se k odběru novinek"}/>
                    </Typography>
                </Modal.Title>*/}
            </Modal.Header>
            <Modal.Body className={"p-5"}>
                <Row xs={1} lg={2}>
                    <Col style={{minHeight: '15rem'}}>
                        <Image className={"w-100 h-100"}>
                            <NextImage
                                src={SEImage}
                                alt={''}
                                fill
                                placeholder={"blur"}
                                sizes={"500px"}
                                style={{
                                    objectFit: 'cover',
                                    maxWidth: "100%",
                                }} />
                        </Image>
                    </Col>

                    {!success && (
                        <Col className={"text-center text-lg-start"}>
                            <Typography fontWeight={"light"} variant={"h4"} as={"h2"} className={"mb-6"}>
                                <FormattedMessage
                                    defaultMessage={"Trendy, novinky a vývoj cen realit exkluzivně do e-mailu"}/>
                            </Typography>

                            <Formik innerRef={formRef}
                                    initialValues={{
                                        email,
                                    }}
                                    validationSchema={yup.object({
                                        email: yup
                                            .string()
                                            .email(intl.formatMessage({defaultMessage: 'Neplatný email'}))
                                            .required(requiredMessage),
                                    })} onSubmit={handleSubmit}>
                                {(fp) => (
                                    <Form onSubmit={fp.handleSubmit}>
                                        <Input name={"email"} type={"email"}
                                               label={intl.formatMessage({defaultMessage: "E-mailová adresa"})}/>

                                        <RecaptchaClaim className={"mt-3"}/>

                                        <SubmitButton className={"mt-3"} variant={"primary"}>
                                            <FormattedMessage defaultMessage={"Odebírat newsletter"}/>
                                        </SubmitButton>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    )}

                    {success && (
                        <Col className={"text-center text-lg-start"}>
                            <Typography fontWeight={"light"} variant={"h4"} as={"h2"}>
                                <FormattedMessage
                                    defaultMessage={"Děkujeme! Od teď s námi budete v obraze."}/>
                            </Typography>

                            <Button className={"mt-3"} variant={"primary"} onClick={props.onHide}>
                                <FormattedMessage defaultMessage={"Zavřít"}/>
                            </Button>
                        </Col>
                    )}
                </Row>
            </Modal.Body>
        </Modal>
    );
};
