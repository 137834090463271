import {FC} from 'react';
import {useIsClient} from 'usehooks-ts';

export const ClientOnly: FC<React.PropsWithChildren> = ({children}) => {
    const isClient = useIsClient();

    if (!isClient) {
        return null;
    }

    return <>{children}</>;
};
