import {NumbersList} from '@components/NumbersList';

export const IntroNumbersSk = () => (
    <NumbersList>
        <NumbersList.Item label="Záujemcov každý mesiac">21&nbsp;000</NumbersList.Item>

        <NumbersList.Item label="Ušetríte za províziu pri predaji">9&nbsp;600&nbsp;€</NumbersList.Item>

        <NumbersList.Item label="Spokojných zákazníkov">9&nbsp;000</NumbersList.Item>

        <NumbersList.Item label="Skúseností na trhu">17 rokov</NumbersList.Item>
    </NumbersList>
);
