import classNames from 'classnames';
import styles from './Tag.module.scss';
import {Icon} from '..';

export type TagCloseProps = {
    onClick: () => void;
    title: string;
};

export const TagClose = ({onClick, title}: TagCloseProps) => (
    <span className={classNames(styles.ctag)} onClick={onClick} title={title}>
        <Icon.Close className={styles.tag_icon} />
    </span>
);
