import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import {Typography, TypographyProps} from '..';
import styles from './CtaCard.module.scss';

type CtaCardHeadingProps = {
    hasBorder?: boolean;
} & TypographyProps;

export const CtaCardHeading = ({as = 'h3', hasBorder = true, children, ...props}: PropsWithChildren<CtaCardHeadingProps>) => {
    return (
        <Typography
            as={as}
            variant="h5"
            fontWeight="medium"
            {...props}
            className={classNames(styles.ctaCardHeading, 'mb-4', {
                'pb-4': hasBorder,
                [styles['ctaCardHeading--bd']]: hasBorder,
            })}
        >
            {children}
        </Typography>
    );
};
