import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import styles from './InspirationCard.module.scss';
import { InspirationCardContent } from './InspirationCardContent';
import { InspirationCardHeading } from './InspirationCardHeading';
import { InspirationCardImage } from './InspirationCardImage';
import { InspirationCardPerex } from './InspirationCardPerex';

export const InspirationCard: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.inspirationCard, 'h-100')} background={false} rounded="xl">
			{children}
		</ContentBox>
	);
};

export default Object.assign(InspirationCard, {
	Content: InspirationCardContent,
	Heading: InspirationCardHeading,
	Image: InspirationCardImage,
	Perex: InspirationCardPerex,
});
