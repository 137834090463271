import classNames from 'classnames';
import React, {FC, useContext, useMemo} from 'react';
import { ContentBox } from '../ContentBox';
import { Row } from '../Row';
import styles from './NewsletterBox.module.scss';
import { NewsletterBoxContent } from './NewsletterBoxContent';
import { NewsletterBoxHeading } from './NewsletterBoxHeading';

type Props = {
    portrait?: boolean;
}

const NewsletterBoxContext = React.createContext({portrait: false});

export const useNewsletterBox = () => useContext(NewsletterBoxContext);

export const NewsletterBox: FC<React.PropsWithChildren<Props>> = ({ children, portrait }) => {
    const value = useMemo(() => ({
        portrait: !!portrait
    }), [portrait]);

	return (
        <NewsletterBoxContext.Provider value={value}>
            <ContentBox className={classNames(styles.newsletterBox, 'newsletterBox')} background="green" color="white">
                <Row className="g-4 align-items-center">{children}</Row>
            </ContentBox>
        </NewsletterBoxContext.Provider>
	);
};

export default Object.assign(NewsletterBox, {
	Heading: NewsletterBoxHeading,
	Content: NewsletterBoxContent,
});
