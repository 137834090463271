import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './InspirationCard.module.scss';

export const InspirationCardPerex: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="p" variant="subheadline" className={classNames(styles.inspirationCardPerex, 'mb-0')} color="grey-dark">
			{children}
		</Typography>
	);
};
