import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Container } from '../Container';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import styles from './InspirationCarousel.module.scss';
import { InspirationCarouselSlide } from './InspirationCarouselSlide';

type InspirationCarouselProps = {
	emblaRef: any;
	navButtons?: ReactNode;
	sideContent?: ReactNode;
	scrollSnaps?: number[];
	selectedIndex?: number;
}

export const InspirationCarousel: FC<React.PropsWithChildren<InspirationCarouselProps>> = ({
	emblaRef,
	navButtons,
	sideContent,
	scrollSnaps,
	selectedIndex,
	children,
}) => {
	return (
		<div className={classNames(styles.inspirationCarousel, 'inspirationCarousel')}>
			<Container>
				<div className={classNames(styles.inspirationCarouselHolder, 'pb-md-15 pb-lg-0')}>
					{sideContent && (
						<div className={classNames(styles.inspirationCarouselSideContent, 'pt-lg-5 pt-xl-10')}>
							<Stack className="h-100">{sideContent}</Stack>
						</div>
					)}

					<div className={styles.inspirationCarouselViewport} ref={emblaRef}>
						<div className={classNames(styles.inspirationCarouselContainer, 'pb-4 pb-md-0')}>
							<InspirationCarouselSlide placeholder />

							{children}
						</div>
					</div>

					{selectedIndex !== undefined && scrollSnaps && (
						<div className={classNames(styles.inspirationCarouselCount, 'd-none d-lg-flex')}>
							<Typography variant="perex" color="grey-dark">
								{selectedIndex + 1} / {scrollSnaps.length}
							</Typography>
						</div>
					)}

					{navButtons && <div className={classNames(styles.inspirationCarouselNav, 'd-none d-md-block')}>{navButtons}</div>}
				</div>
			</Container>
		</div>
	);
};

export default Object.assign(InspirationCarousel, {
	Slide: InspirationCarouselSlide,
});
