import classNames from 'classnames';
import React, {FC, useContext} from 'react';
import {Typography, TypographyProps} from '../Typography';
import styles from './HeroIntro.module.scss';
import {HeroIntroContext} from './HeroIntroContext';

type HeroIntroHeadingProps = {
    variant?: TypographyProps['variant'];
    center?: boolean;
};

export const HeroIntroHeading: FC<React.PropsWithChildren<HeroIntroHeadingProps>> = ({variant = 'h1', center = false, children}) => {
    const {variant: heroIntroVariant} = useContext(HeroIntroContext);

    return (
        <Typography
            as="h1"
            variant={variant}
            fontWeight={heroIntroVariant === 'hp' ? 'bold' : undefined}
            className={classNames(
                styles.heroIntroHeading,
                heroIntroVariant === 'hp' ? 'mb-4 mb-md-6' : classNames('mb-5', {'mb-md-8': heroIntroVariant !== 'lp'}),
                {
                    'text-center': center,
                },
            )}
        >
            {children}
        </Typography>
    );
};
