import classNames from 'classnames';
import React, {FC, forwardRef} from 'react';
import {Button, ButtonProps} from '../Button';
import {Icon} from '../Icon';
import styles from './PromoCardLarge.module.scss';

type PromoCardLargeButtonProps = ButtonProps & {
    autoMargin?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const PromoCardLargeButton: FC<React.PropsWithChildren<PromoCardLargeButtonProps>> = forwardRef<
    HTMLButtonElement,
    PromoCardLargeButtonProps
>(({children, variant = 'link', autoMargin, ...props}, ref) => {
    return (
        <div className={classNames(styles.promoCardLargeButton, 'pt-5 pt-xl-9 mt-12', !!autoMargin ? `mt-${autoMargin}-auto` : 'mt-xl-10')}>
            <Button ref={ref} {...props} variant={variant} className={classNames({'w-100': variant === 'link'})} sideToSide>
                {children}
                <Icon.ArrowRight position="end" />
            </Button>
        </div>
    );
});
