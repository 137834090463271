import classNames from 'classnames';
import {PropsWithChildren, ReactNode} from 'react';
import {Typography} from '..';
import styles from './NumbersList.module.scss';

type NumbersListItemProps = {
    label: ReactNode;
    size?: 'sm' | 'md';
};

export const NumbersListItem = ({label, size = 'md', children}: PropsWithChildren<NumbersListItemProps>) => {
    return (
        <li className={classNames(styles.numbersListItem, 'd-flex flex-column')}>
            <Typography
                as="strong"
                variant={
                    {
                        sm: 'h5' as const,
                        md: 'h2' as const,
                    }[size]
                }
                fontWeight="medium"
                className={classNames(
                    styles.numbersListItemValue,
                    {
                        [styles[`numbersListItemValue--${size}`]]: size,
                    },
                    'mb-0',
                )}
            >
                {children}
            </Typography>

            <Typography
                variant={
                    {
                        sm: 'body' as const,
                        md: 'h5' as const,
                    }[size]
                }
                fontWeight={size === 'md' ? 'medium' : undefined}
                className={classNames(
                    styles.numbersListItemLabel,
                    {
                        [styles[`numbersListItemLabel--${size}`]]: size,
                    },
                    'mb-0',
                )}
            >
                {label}
            </Typography>
        </li>
    );
};
