import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './LargeBadge.module.scss';

export const LargeBadge: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography
			as="p"
			className={classNames(
				styles.largeBadge,
				'p-6 mb-0 bg-green text-white text-center d-flex justify-content-center align-items-center fw-medium text-ls text-uppercase',
			)}
		>
			{children}
		</Typography>
	);
};
