import classNames from 'classnames';
import React, {PropsWithChildren, ReactNode, useMemo} from 'react';
import {ContentBox} from '../ContentBox';
import {Stack} from '../Stack';
import {Typography} from '../Typography';
import styles from './PromoCardLarge.module.scss';
import {PromoCardLargeButton} from './PromoCardLargeButton';
import {PromoCardLargeContent} from './PromoCardLargeContent';
import {PromoCardLargeContext} from './PromoCardLargeContext';
import {PromoCardLargeDesc} from './PromoCardLargeDesc';
import {PromoCardLargeHeading} from './PromoCardLargeHeading';

export type PromoCardLargeProps = {
    mainHeading?: ReactNode;
    variant?: 'white' | 'green' | 'blank' | 'blue';
    className?: string;
    disableLinkMask?: boolean;
} & PropsWithChildren;

export const PromoCardLarge = ({variant = 'blank', mainHeading, className, children, disableLinkMask}: PromoCardLargeProps) => {
    const context = useMemo(() => ({variant}), [variant]);
    const content = (
        <ContentBox
            className={classNames(styles.promoCardLarge, 'pt-12 pb-6 px-5 p-xl-10', mainHeading ? 'h-auto h-md-100' : 'h-100', className, {
                [styles[`promoCardLarge--${variant}`]]: variant,
                [styles['promoCardLarge--disable-link-mask']]: disableLinkMask,
            })}
            background={variant === 'blank' ? false : variant}
        >
            <Stack className="h-100">{children}</Stack>
        </ContentBox>
    );

    return (
        <PromoCardLargeContext.Provider value={context}>
            {mainHeading ? (
                <ContentBox
                    background={variant === 'blank' ? false : variant}
                    className={classNames(styles.promoCardLargeHolder, 'p-0 h-100', {
                        [styles[`promoCardLargeHolder--${variant}`]]: variant,
                    })}
                >
                    <Typography as="h2" className={classNames(styles.promoCardLargeMainHeading, 'mb-0 pt-8 px-5 py-xl-14 px-xl-15')}>
                        {mainHeading}
                    </Typography>

                    {content}
                </ContentBox>
            ) : (
                content
            )}
        </PromoCardLargeContext.Provider>
    );
};

export default Object.assign(PromoCardLarge, {
    Button: PromoCardLargeButton,
    Content: PromoCardLargeContent,
    Desc: PromoCardLargeDesc,
    Heading: PromoCardLargeHeading,
});
