import {Dispatch} from 'react';

import {Col} from '@components/Col';
import {Row} from '@components/Row';
import {ToggleButton} from '@components/ToggleButton';

import {Action, RadioFilterEnum} from './types';
import {entries} from 'src/types/general';
import {translations as estateTranslations} from '@constants/EstateType';
import {setRadioFilter} from './reducer';

const ESTATE_TYPES = entries(estateTranslations);

type SearchPanelEstateProps = {
    dispatch: Dispatch<Action>;
};

export const SearchPanelEstate = ({dispatch}: SearchPanelEstateProps) => {
    return (
        <Row className="g-3 mb-8">
            {ESTATE_TYPES.map((row) => (
                <Col xs={12} sm={6} key={row[0]}>
                    <ToggleButton
                        size="lg"
                        type="radio"
                        name="condition"
                        id={`condition-${row[0]}`}
                        value={row[0]}
                        onClick={setRadioFilter(dispatch)({attr: RadioFilterEnum.Estate, value: row[0]})}
                    >
                        {row[1]}
                    </ToggleButton>
                </Col>
            ))}
        </Row>
    );
};
