import classNames from 'classnames';
import React, {FC, useMemo} from 'react';
import styles from './HeroIntro.module.scss';
import {HeroIntroBackground} from './HeroIntroBackground';
import {HeroIntroBottom} from './HeroIntroBottom';
import {HeroIntroContent} from './HeroIntroContent';
import {HeroIntroContext} from './HeroIntroContext';
import {HeroIntroHeading} from './HeroIntroHeading';
import {HeroIntroPromoButton} from './HeroIntroPromoButton';
import {HeroIntroSide} from './HeroIntroSide';
import {HeroIntroTop} from './HeroIntroTop';
import {HeroIntroCenter} from '@components/HeroIntro/HeroIntroCenter';

export type HeroIntroProps = {
    variant?: 'lp' | 'big' | 'blog' | 'hp';
};

export const HeroIntro: FC<React.PropsWithChildren<HeroIntroProps>> = ({variant, children}) => {
    const context = useMemo(() => ({variant}), [variant]);

    return (
        <HeroIntroContext.Provider value={context}>
            <div
                className={classNames(
                    styles.heroIntro,
                    {
                        [styles[`heroIntro--${variant}`]]: variant,
                    },
                    'heroIntro',
                )}
            >
                {children}
            </div>
        </HeroIntroContext.Provider>
    );
};

export default Object.assign(HeroIntro, {
    Background: HeroIntroBackground,
    Bottom: HeroIntroBottom,
    Content: HeroIntroContent,
    Heading: HeroIntroHeading,
    PromoButton: HeroIntroPromoButton,
    Side: HeroIntroSide,
    Top: HeroIntroTop,
    Center: HeroIntroCenter,
});
