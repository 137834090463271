import React, { FC, forwardRef } from 'react';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';
import styles from './SearchPanel.module.scss';
import classNames from "classnames";

export const SearchPanelButton: FC<React.PropsWithChildren<ButtonProps>> = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, ...props }, ref) => {
	return (
		<Button ref={ref} {...props} size="lg" className={classNames(className, styles.searchPanelButton)}>
			<VisuallyHidden>{children}</VisuallyHidden>
			<Icon.Search position="middle" size="xl" />
		</Button>
	);
});
