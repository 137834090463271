import {PropsWithChildren} from 'react';
import {Typography} from '../Typography';

export const CtaCardButton = ({children}: PropsWithChildren) => {
    return (
        <Typography as="div" textAlign="center" className="mt-auto">
            {children}
        </Typography>
    );
};
