import classNames from 'classnames';
import React, {FC, useContext} from 'react';
import styles from './HeroIntro.module.scss';
import {HeroIntroContext} from './HeroIntroContext';

export const HeroIntroContent: FC<React.PropsWithChildren> = ({children}) => {
    const {variant: heroIntroVariant} = useContext(HeroIntroContext);

    return (
        <div
            className={classNames(
                styles.heroIntroContent,
                'heroIntroContent mb-last-0 px-4',
                heroIntroVariant === 'hp' ? 'pt-7 px-md-5 pt-xxl-16' : 'py-9 p-md-5 p-xl-8 pb-xxl-10 px-xxl-10',
            )}
        >
            {children}
        </div>
    );
};
