import classNames from 'classnames';
import React, {FC} from 'react';
import styles from './HeroIntro.module.scss';

type Props = {
    center?: boolean;
}

export const HeroIntroTop: FC<React.PropsWithChildren<Props>> = ({children, center = false}) => {
    return (
        <div
            className={classNames(styles.heroIntroTop, 'mb-10', {
                'justify-content-center': center,
                'd-flex': center,
            })}
        >
            {children}
        </div>
    );
};
