import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailByUlidQueryVariables = Types.Exact<{
  ulid: Types.Scalars['String']['input'];
}>;


export type EmailByUlidQuery = { __typename?: 'Query', email?: string | null };


export const EmailByUlidDocument = gql`
    query EmailByUlid($ulid: String!) {
  email(ulid: $ulid)
}
    `;

/**
 * __useEmailByUlidQuery__
 *
 * To run a query within a React component, call `useEmailByUlidQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailByUlidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailByUlidQuery({
 *   variables: {
 *      ulid: // value for 'ulid'
 *   },
 * });
 */
export function useEmailByUlidQuery(baseOptions: Apollo.QueryHookOptions<EmailByUlidQuery, EmailByUlidQueryVariables> & ({ variables: EmailByUlidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailByUlidQuery, EmailByUlidQueryVariables>(EmailByUlidDocument, options);
      }
export function useEmailByUlidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailByUlidQuery, EmailByUlidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailByUlidQuery, EmailByUlidQueryVariables>(EmailByUlidDocument, options);
        }
export function useEmailByUlidSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailByUlidQuery, EmailByUlidQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailByUlidQuery, EmailByUlidQueryVariables>(EmailByUlidDocument, options);
        }
export type EmailByUlidQueryHookResult = ReturnType<typeof useEmailByUlidQuery>;
export type EmailByUlidLazyQueryHookResult = ReturnType<typeof useEmailByUlidLazyQuery>;
export type EmailByUlidSuspenseQueryHookResult = ReturnType<typeof useEmailByUlidSuspenseQuery>;
export type EmailByUlidQueryResult = Apollo.QueryResult<EmailByUlidQuery, EmailByUlidQueryVariables>;