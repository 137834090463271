import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './SearchPanel.module.scss';
import { SearchPanelButton } from './SearchPanelButton';
import { SearchPanelInput } from './SearchPanelInput';
import { SearchPanelInputButton } from './SearchPanelInputButton';
import { SearchPanelInputDropdown } from './SearchPanelInputDropdown';
import { SearchPanelInputsGroup } from './SearchPanelInputsGroup';
import { SearchPanelInputsItem } from './SearchPanelInputsItem';

export const SearchPanel: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={classNames(styles.searchPanel, 'searchPanel')}>{children}</div>;
};

export default Object.assign(SearchPanel, {
	Button: SearchPanelButton,
	Input: SearchPanelInput,
	InputButton: SearchPanelInputButton,
	InputDropdown: SearchPanelInputDropdown,
	InputsItem: SearchPanelInputsItem,
	InputsGroup: SearchPanelInputsGroup,
});
