import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import {Typography, TypographyProps} from '..';
import styles from './CtaCard.module.scss';

export const CtaCardText = ({children}: PropsWithChildren<TypographyProps>) => {
    return (
        <Typography as="div" className={classNames(styles.ctaCardText, 'mb-last-0 mb-4')}>
            {children}
        </Typography>
    );
};
