import React, { FC } from 'react';
import { Button, ButtonProps } from '../Button';
import styles from './CarouselNav.module.scss';

type CarouselNavButtonProps = ButtonProps & {};

export const CarouselNavButton: FC<React.PropsWithChildren<CarouselNavButtonProps>> = ({ children, ...props }) => {
	return (
		<Button {...props} variant="outline-dark" size="lg" className={styles.carouselNavButton}>
			{children}
		</Button>
	);
};
