import classNames from 'classnames';
import React, { FC } from 'react';
import { Box } from '../Box';
import { Image } from '../Image';
import styles from './InspirationCard.module.scss';

export const InspirationCardImage: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Box className={classNames(styles.inspirationCardImage, 'overflow-hidden')} rounded="lg">
			<Image layout="fill">{children}</Image>
		</Box>
	);
};
