import React, {ComponentProps, FC} from 'react';
import { Col } from '../Col';
import { ContentBox } from '../ContentBox';
import styles from './NewsletterBox.module.scss';
import {useNewsletterBox} from "@components/NewsletterBox/NewsletterBox";

type Props = {
    background?: ComponentProps<typeof ContentBox>["background"];
}

export const NewsletterBoxContent: FC<React.PropsWithChildren<Props>> = ({ children, background }) => {
    const {portrait} = useNewsletterBox();
	return (
		<Col lg={!portrait ? 10 : 16} xl={!portrait ? 8 : 16} className={styles.newsletterBoxContent}>
			<ContentBox background={background ?? "white"}>{children}</ContentBox>
		</Col>
	);
};
