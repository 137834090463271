import {Dispatch} from 'react';
import {FormattedMessage} from 'react-intl';

import {Button} from '@components/Button';
import {Icon} from '@components/Icon';
import {SearchPanelSidebar} from '@components/SearchPanelSidebar';
import {Sidebar} from '@components/Sidebar';
import {Typography} from '@components/Typography';
import {Action, State, StepsEnum} from './types';
import {SearchPanelOffer} from './SearchPanelOffer';
import {SearchPanelEstate} from './SearchPanelEstate';
import {SearchPanelLocality} from './SearchPanelLocality';
import {SearchPanelPrice} from './SearchPanelPrice';

type SearchPanelMobileProps = {
    onHide: () => void;
    state: State;
    dispatch: Dispatch<Action>;
};

export const SearchPanelMobile = ({state, onHide, dispatch}: SearchPanelMobileProps) => (
    <Sidebar specialVariant="overlay" placement="bottom" show={state.showSearchSidebar}>
        <SearchPanelSidebar>
            <SearchPanelSidebar.Header>
                <Button variant="link-light" className="mb-3" onClick={onHide}>
                    <Icon.ArrowLeft position="start" size="sm" />
                    <FormattedMessage defaultMessage="Zavřít" />
                </Button>

                <Typography as="h2">
                    <FormattedMessage defaultMessage="Jakou nabídku hledáte?" />
                </Typography>
            </SearchPanelSidebar.Header>

            <SearchPanelSidebar.Body>
                {state.step === StepsEnum.Offer && <SearchPanelOffer dispatch={dispatch} />}
                {state.step === StepsEnum.Estate && <SearchPanelEstate dispatch={dispatch} />}
                {state.step === StepsEnum.Locality && <SearchPanelLocality dispatch={dispatch} />}
                {state.step === StepsEnum.Price && <SearchPanelPrice state={state} dispatch={dispatch} />}
            </SearchPanelSidebar.Body>
        </SearchPanelSidebar>
    </Sidebar>
);
